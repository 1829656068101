import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import RenderOnRole from "./RenderOnRole";
import UserService from "../services/UserService";
import CallApi from "./CallApi";
import Clients from "./Clients";
import React, { useState } from "react";

const App = () => {

    const handleChangeKeycloak = (event) => {
        setState({...state,  keycloakEndpoint: event.target.value });
        window.localStorage.setItem("keycloakEndpoint", event.target.value);
        UserService.initKeycloak(event.target.value, this);
    };
    const handleChangeBackend = (event) => {
        setState({...state,  backendEndpoint: event.target.value });
        window.localStorage.setItem("backendEndpoint", event.target.value);
    };
    const handleChangeService = (event) => {
        setState({...state,  serviceEndpoint: event.target.value });
        window.localStorage.setItem("serviceEndpoint", event.target.value);
    };
    const handleChangeClientManagementScope = (event) => {
        setState({...state,  clientManagementScope: event.target.checked });
        window.localStorage.setItem("clientManagement", event.target.checked);
    };


    const [state, setState] = useState({
        keycloakEndpoint: window.localStorage.getItem("keycloakEndpoint") || "https://authentication.skye.xbrl.io",
        backendEndpoint: window.localStorage.getItem("backendEndpoint") || "https://test-backend.skye.xbrl.io",
        serviceEndpoint: window.localStorage.getItem("serviceEndpoint") || "https://test-service.skye.xbrl.io",
        clientManagementScope: window.localStorage.getItem("clientManagement") === "true" || false,
        showResult: false,
        request: "",
        response: ""
    });
    return (
        <>
            <style>{
                ".bluegroup { border-left: 10px solid #00c8d7; }" +
                ".greengroup { border-left: 10px solid #87f542; }" +
                ".redgroup { border-left: 10px solid #de5959; }"
            }</style>
            <div>
                Keycloak endpoint:
                <select id="keycloak"
                        name="keycloak"
                        defaultValue={window.localStorage.getItem("keycloakEndpoint") || state.keycloakEndpoint}
                        onChange={e => handleChangeKeycloak(e)}>
                    <option value="https://authentication.skye.xbrl.io">
                        Production (https://authentication.skye.xbrl.io)
                    </option>
                    <option value="https://authentication.staging.skye.xbrl.io">
                        Staging (https://authentication.staging.skye.xbrl.io)
                    </option>
                    <option value="https://authentication.skye.k8s">
                        Development (https://authentication.skye.k8s)
                    </option>
                    <option value="https://authentication.skye.dennis.dev.xbrl.io">
                        Dennis (https://authentication.skye.dennis.dev.xbrl.io)
                    </option>
                    <option value="https://authentication.skye.federico.dev.xbrl.io">
                        Federico (https://authentication.skye.federico.dev.xbrl.io)
                    </option>
                    <option value="https://authentication.skye.hedi.dev.xbrl.io">
                        Hedi (https://authentication.skye.hedi.dev.xbrl.io)
                    </option>
                    <option value="https://authentication.skye.mary.dev.xbrl.io">
                        Mary (https://authentication.skye.mary.dev.xbrl.io)
                    </option>
                    <option value="https://authentication.skye.mootez.dev.xbrl.io">
                        Mootez (https://authentication.skye.mootez.dev.xbrl.io)
                    </option>
                </select>
            </div>
            <div>
                Backend endpoint:
                <select id="backend"
                        name="backend"
                        defaultValue={window.localStorage.getItem("backendEndpoint", state.backendEndpoint)}
                        onChange={e => handleChangeBackend(e)}>
                    <option value="https://test-backend.authentication.skye.xbrl.io">
                        Production (https://test-backend.authentication.skye.xbrl.io)
                    </option>
                    <option value="https://test-backend.authentication.staging.skye.xbrl.io">
                        Staging (https://test-backend.authentication.staging.skye.xbrl.io)
                    </option>
                    <option value="https://test-backend.authentication.skye.k8s">
                        Development (https://test-backend.authentication.skye.k8s)
                    </option>
                    <option value="https://test-backend.authentication.skye.dennis.dev.xbrl.io">
                        Dennis (https://test-backend.authentication.skye.dennis.dev.xbrl.io)
                    </option>
                    <option value="https://test-backend.authentication.skye.federico.dev.xbrl.io">
                        Federico (https://test-backend.authentication.skye.federico.dev.xbrl.io)
                    </option>
                    <option value="https://test-backend.authentication.skye.hedi.dev.xbrl.io">
                        Hedi (https://test-backend.authentication.skye.hedi.dev.xbrl.io)
                    </option>
                    <option value="https://test-backend.authentication.skye.mary.dev.xbrl.io">
                        Mary (https://test-backend.authentication.skye.mary.dev.xbrl.io)
                    </option>
                    <option value="https://test-backend.authentication.skye.mootez.dev.xbrl.io">
                        Mootez (https://test-backend.authentication.skye.mootez.dev.xbrl.io)
                    </option>
                    <option value="http://localhost:3010">
                        Unpackaged (http://localhost:3010)
                    </option>
                </select>
            </div>
            <div>
                Service endpoint:
                <select id="service"
                        name="service"
                        defaultValue={window.localStorage.getItem("serviceEndpoint", state.serviceEndpoint)}
                        onChange={e => handleChangeService(e)}>
                    <option value="https://test-service.authentication.skye.xbrl.io">
                        Production (https://test-service.authentication.skye.xbrl.io)
                    </option>
                    <option value="https://test-service.authentication.staging.skye.xbrl.io">
                        Staging (https://test-service.authentication.staging.skye.xbrl.io)
                    </option>
                    <option value="https://test-service.authentication.skye.k8s">
                        Development (https://test-service.authentication.skye.k8s)
                    </option>
                    <option value="https://test-service.authentication.skye.dennis.dev.xbrl.io">
                        Dennis (https://test-service.authentication.skye.dennis.dev.xbrl.io)
                    </option>
                    <option value="https://test-service.authentication.skye.federico.dev.xbrl.io">
                        Federico (https://test-service.authentication.skye.federico.dev.xbrl.io)
                    </option>
                    <option value="https://test-service.authentication.skye.hedi.dev.xbrl.io">
                        Hedi (https://test-service.authentication.skye.hedi.dev.xbrl.io)
                    </option>
                    <option value="https://test-service.authentication.skye.mary.dev.xbrl.io">
                        Mary (https://test-service.authentication.skye.mary.dev.xbrl.io)
                    </option>
                    <option value="https://test-service.authentication.skye.mootez.dev.xbrl.io">
                        Mootez (https://test-service.authentication.skye.mootez.dev.xbrl.io)
                    </option>
                    <option value="http://localhost:3011">
                        Unpackaged (http://localhost:3011)
                    </option>
                </select>
            </div>
            <div>
                <input
                    type="checkbox"
                    id="clientManagementScope"
                    name="clientManagementScope"
                    checked={state.clientManagementScope}
                    onChange={handleChangeClientManagementScope}
                    disabled={false}
                />Request client management scope when logging in<br/>
            </div>

            <br/>
            <div className="container">
                <nav className="navbar navbar-default">
                    <div className="container-fluid">
                        <RenderOnAnonymous>
                            <div id="navbar">
                                <button className="btn btn-success navbar-btn navbar-right" style={{marginRight: 0}}
                                        onClick={() => UserService.doLogin(state.clientManagementScope ? {"scope": "client_management"} : {})}>Login
                                </button>
                                <p className="navbar-text navbar-right" style={{marginRight: 15}}>Not Signed in</p>
                            </div>
                        </RenderOnAnonymous>
                        <RenderOnAuthenticated>
                            <div id="navbar">
                                <button className="btn btn-success navbar-btn navbar-right" style={{marginRight: 0}}
                                        onClick={() => UserService.doLogout()}>Logout
                                </button>
                                <p className="navbar-text navbar-right" style={{marginRight: 15}}>
                                    Signed in as &nbsp;
                                    <a href={state.keycloakEndpoint + "/realms/skye/account"}>{UserService.getUsername()}</a>.
                                    You are allowed to: login
                                    <RenderOnRole roles={['client_management']}>, <b>manage clients</b></RenderOnRole>
                                    {/*<RenderOnRole roles={['extra_role']}><b>, do special API calls</b></RenderOnRole>*/}
                                    .

                                </p>
                            </div>
                        </RenderOnAuthenticated>
                    </div>
                </nav>

                <RenderOnAuthenticated>
      <pre>
        {"Account Roles: " + UserService.getAccountRoles() + "\nRealm Roles: " + UserService.getRealmRoles() + "\nScopes: " + UserService.getScopes()}
      </pre>
                    Raw Token:
                    <pre>
                  {UserService.getToken()}
              </pre>
                    Parsed Token:
                    <pre>
                  {JSON.stringify(UserService.getParsedToken(), null, 4)}
              </pre>
                </RenderOnAuthenticated>
                {}
                <hgroup className="bluegroup">
                    Resource server API calls<br/>
                    <CallApi baseURL={state.backendEndpoint} URL="/api/anonymous"/>
                    <br/>
                </hgroup>
                <br/><br/>
                <hgroup className="greengroup">
                    Service API calls<br/>
                    <CallApi baseURL={state.serviceEndpoint} URL="/api/anonymous"/>
                    <br/>
                </hgroup>
                <br/><br/>
                {/*<hgroup className="redgroup">*/}
                {/*    Client management<br/><br/>*/}
                {/*    <Clients*/}
                {/*        clientsURL={state.keycloakEndpoint + "/realms/skye/client-management"}*/}
                {/*        tokenExchangeURL={state.keycloakEndpoint}*/}
                {/*        callbackURL={state.keycloakEndpoint}*/}
                {/*        openIdTokenURL={state.keycloakEndpoint}*/}
                {/*        openIdAuthURL={state.keycloakEndpoint}*/}
                {/*    />*/}
                {/*</hgroup>*/}
                <br/>
            </div>
        </>
    );
}

export default App;
