import Keycloak from "keycloak-js";

var _kc;

const initKeycloak = (endpoint, onAuthenticatedCallback) => {
  _kc = new Keycloak({
    "url": endpoint,
    "realm": "skye",
    "clientId": "frontend"
  });
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

// const doLogin = () => _kc.login({"scope": "client_management extra_scope"});
// const doLogin = () => _kc.login({"scope": "client_management"});
const doLogin = (options) => _kc.login(options);

const doLogout = () => _kc.logout();

const getToken = () => _kc.token;

const getParsedToken = () => _kc.tokenParsed

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(_kc.login);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getAccountRoles = () => _kc.tokenParsed?.resource_access.account.roles.join(", ");
const getRealmRoles = () => _kc.tokenParsed?.realm_access.roles.join(", ");
const getScopes = () => _kc.tokenParsed?.scope;

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getParsedToken,
  getToken,
  updateToken,
  getUsername,
  getAccountRoles,
  getRealmRoles,
  getScopes,
  hasRole,
};

export default UserService;
