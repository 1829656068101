import React, { useState } from "react";
import PropTypes from 'prop-types'
import UserService from "../services/UserService";

export const CallApi = ({ baseURL }) => {
  const [state, setState] = useState({
    showResult: false,
    request: "",
    response: ""
  });

  const doCallApi = async (event) => {
    const URL = event.target.getAttribute('arg-url');
    const requestURL = `${baseURL}${URL}`;
    const requestHeaders = UserService.isLoggedIn() ? {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
      }} : null;

    try {
      setState({...state, showResult: true, request: `Pending ...`, response: `Pending ...`});
      await new Promise(r => setTimeout(r, 400));
      const response = await fetch(requestURL, requestHeaders);

      const responseBody = await response.text();
      const responseStatus = response.status;

      setState({...state, showResult: true, request: `GET ${requestURL}\n${requestHeaders !== null ? "Authorization: " + requestHeaders.headers.Authorization :""}`, response: `${responseStatus}\n${responseBody}`});
    } catch (error) {
      setState({...state, showResult: true, request: `GET ${requestURL}\n${requestHeaders !== null ? "Authorization: " + requestHeaders.headers.Authorization :""}`, response: "Error: " + error});
    }
  };

  return (
    <>
      <button onClick={doCallApi} arg-url="/api/anybody">{`GET ${baseURL}/api/anybody`}</button><br/>
      <button onClick={doCallApi} arg-url="/api/any-user">{`GET ${baseURL}/api/any-user`}</button><br/>
      <button onClick={doCallApi} arg-url="/api/token-attributes">{`GET ${baseURL}/api/token-attributes`}</button><br/>
      <button onClick={doCallApi} arg-url="/api/role-required">{`GET ${baseURL}/api/role-required`}</button><br/>
      <button onClick={doCallApi} arg-url="/api/scope-required">{`GET ${baseURL}/api/scope-required`}</button><br/>
      <button onClick={doCallApi} arg-url="/api/role-and-scope-required">{`GET ${baseURL}/api/role-and-scope-required`}</button><br/>
      {
        state.showResult ? (<><br/><br/><pre>{state.request}</pre><pre>{state.response}</pre></>) : <></>
      }
    </>
  );

};

export default CallApi
